<template>
  <div class="container">
    <div class="ui-border-line"></div>

    <template v-if="!pageOptions.hiddenSearchConditionController && !query.recommGroupUid && $route.path === '/artist/celebrity'">
      <div class="ui-border-line"></div>
    </template>
    <div class="scroll-container" ref="container">
      <div class="celebrity-banner">
        <div class="celebrity-banner-bg">
          <img src="/img/artist/video_celebrity_960_15.gif" />
        </div>
        <div class="celebrity-banner-content">
          <h2 class="celebrity-banner-txt">
            특별한 행사의 시작<br>
            연예인, 셀럽 섭외
          </h2>
          <div class="celebrity-banner-btns">
            <button type="button" class="btn-celebrity-inquiry" @click="scrollToElement()">
              <span>섭외 문의하기</span>
            </button>
            <button type="button" class="btn-celebrity-chat" @click="() => $gotoWeb('http://pf.kakao.com/_yzxhwC/chat')">
              <span>채팅으로 상담받기</span>
            </button>
          </div>
        </div>
      </div>
      <div class="celebrity-marquee">
        <div class="celebrity-marquee-inner">
          <ul>
            <li>
              <img src="/img/artist/logo_HYBE.png" alt="HYBE">
            </li>
            <li>
              <img src="/img/artist/logo_SM.png" alt="SM Ent">
            </li>
            <li>
              <img src="/img/artist/logo_JYP.png" alt="JYP Ent">
            </li>
            <li>
              <img src="/img/artist/logo_YG.png" alt="YG Ent">
            </li>
            <li>
              <img src="/img/artist/logo_Antenna.png" alt="Antenna Ent">
            </li>
            <li>
              <img src="/img/artist/logo_Brave.png" alt="Brave Ent">
            </li>
            <li>
              <img src="/img/artist/logo_AOMG.png" alt="AOMG Ent">
            </li>
            <li>
              <img src="/img/artist/logo_PNATION.png" alt="P NATION Ent">
            </li>
            <li>
              <img src="/img/artist/logo_MYSTIC.png" alt="MYSTIC Ent">
            </li>
            <li>
              <img src="/img/artist/logo_PLEDIS.png" alt="PLEDIS Ent">
            </li>
          </ul>
          <ul class="marquee-copy">
            <li>
              <img src="/img/artist/logo_HYBE.png" alt="HYBE">
            </li>
            <li>
              <img src="/img/artist/logo_SM.png" alt="SM Ent">
            </li>
            <li>
              <img src="/img/artist/logo_JYP.png" alt="JYP Ent">
            </li>
            <li>
              <img src="/img/artist/logo_YG.png" alt="YG Ent">
            </li>
            <li>
              <img src="/img/artist/logo_Antenna.png" alt="Antenna Ent">
            </li>
            <li>
              <img src="/img/artist/logo_Brave.png" alt="Brave Ent">
            </li>
            <li>
              <img src="/img/artist/logo_AOMG.png" alt="AOMG Ent">
            </li>
            <li>
              <img src="/img/artist/logo_PNATION.png" alt="P NATION Ent">
            </li>
            <li>
              <img src="/img/artist/logo_MYSTIC.png" alt="MYSTIC Ent">
            </li>
            <li>
              <img src="/img/artist/logo_PLEDIS.png" alt="PLEDIS Ent">
            </li>
          </ul>
        </div>
        <div class="celebrity-marquee-inner reverse">
          <ul>
            <li>
              <img src="/img/artist/logo_DSP.png" alt="DSP Ent">
            </li>
            <li>
              <img src="/img/artist/logo_Brandnew.png" alt="Brandnew Ent">
            </li>
            <li>
              <img src="/img/artist/logo_CUBE.png" alt="CUBE Ent">
            </li>
            <li>
              <img src="/img/artist/logo_Starship.png" alt="Starship Ent">
            </li>
            <li>
              <img src="/img/artist/logo_EDAM.png" alt="EDAM Ent">
            </li>
            <li>
              <img src="/img/artist/logo_fantagio.png" alt="fantagio Ent">
            </li>
            <li>
              <img src="/img/artist/logo_CJENM.png" alt="CJ ENM">
            </li>
            <li>
              <img src="/img/artist/logo_FNC.png" alt="FNC Ent">
            </li>
            <li>
              <img src="/img/artist/logo_woollin.png" alt="woollin Ent">
            </li>
            <li>
              <img src="/img/artist/logo_JDB.png" alt="JDB Ent">
            </li>
          </ul>
          <ul class="marquee-copy">
            <li>
              <img src="/img/artist/logo_DSP.png" alt="DSP Ent">
            </li>
            <li>
              <img src="/img/artist/logo_Brandnew.png" alt="Brandnew Ent">
            </li>
            <li>
              <img src="/img/artist/logo_CUBE.png" alt="CUBE Ent">
            </li>
            <li>
              <img src="/img/artist/logo_Starship.png" alt="Starship Ent">
            </li>
            <li>
              <img src="/img/artist/logo_EDAM.png" alt="EDAM Ent">
            </li>
            <li>
              <img src="/img/artist/logo_fantagio.png" alt="fantagio Ent">
            </li>
            <li>
              <img src="/img/artist/logo_CJENM.png" alt="CJ ENM">
            </li>
            <li>
              <img src="/img/artist/logo_FNC.png" alt="FNC Ent">
            </li>
            <li>
              <img src="/img/artist/logo_woollin.png" alt="woollin Ent">
            </li>
            <li>
              <img src="/img/artist/logo_JDB.png" alt="JDB Ent">
            </li>
          </ul>
        </div>
      </div>
      <div class="celebrity-count-content" ref="intersectionObserverTarget">
        <h2 class="celebrity-count-title">
          검증된 섭외 플랫폼,
          <span>헤이비글</span>
        </h2>
        <ul>
          <li v-for="(row, key) in countInfo"  :key="key" >
            <div class="celebrity-count-subtitle" :class="row.icon" >{{row.title}}</div>
            <div class="count-box">
              <span :class="'count-num count-num'+key">{{ currentValues[key] !== undefined ? currentValues[key].toLocaleString() : row.number }}</span><span>+</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="celebrity-info">
        <div class="celebrity-info-inner">
          <h2 class="celebrity-info-title">
            완벽한 행사를 위해<br>안전하게 섭외하세요
          </h2>
          <ul class="celebrity-info-content">
            <li>
              <div class="celebrity-info-txt">
                <div class="celebrity-info-txt-title">빠르고 쉬운 견적 비교</div>
                <span>
                  시간과 에너지를 아끼세요.<br>10분만에 견적비교 끝!
                </span>
              </div>
              <div class="celebrity-info-img time"></div>
            </li>
            <li>
              <div class="celebrity-info-txt">
                <div class="celebrity-info-txt-title">검증된 전문가</div>
                <span>
                  신원인증부터 경력, 프로필까지<br>철저한 검증을 진행해요.
                </span>
              </div>
              <div class="celebrity-info-img expert"></div>
            </li>
            <li>
              <div class="celebrity-info-txt">
                <div class="celebrity-info-txt-title">대금 보호 시스템</div>
                <span>
                  행사가 끝난 후 지급하는<br> 방식이라 환불도 쉽고 안전해요.
                </span>
              </div>
              <div class="celebrity-info-img sequre"></div>
            </li>
            <li>
              <div class="celebrity-info-txt">
                <div class="celebrity-info-txt-title">노쇼, 불참, 분쟁 0건</div>
                <span>
                  철저한 환불 및 보상금 제도가 있어<br>안정된 섭외, 공연이 가능해요.
                </span>
              </div>
              <div class="celebrity-info-img noshow"></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="celebrity-review">
        <h2 class="celebrity-review-title">이미 많은 분들이 이용하고 있어요</h2>
        <ul class="celebrity-review-content">
          <li>
            <div class="celebrity-review-category">대기업 S사</div>
            <div class="celebrity-review-content-title">딱 좋은 팀을 골라줘요</div>
            <div class="celebrity-review-txt">
              업무를 새로 맡아서 모르는 것도 많고<br>
              어떤 공연팀을 골라야 할지 난감했는데요.<br>
              담당 매니저님께서 <span>딱 맞는 팀을 추천</span>해 주셨고,<br>
              <span>행사 후 반응도 너무 좋아서</span><br>
              <span>회사에서 칭찬</span>을 많이 받았습니다.
            </div>
          </li>
          <li>
            <div class="celebrity-review-category">중견기업 P사</div>
            <div class="celebrity-review-content-title">피드백이 빨라요</div>
            <div class="celebrity-review-txt">
              급하게 연예인 공연이 필요하게 되어 이용하게 되었는데요.<br>
              <span>문의를 남기자마자 상담</span>해주셨고 저희가 <span>원하는 조건</span>의 분들을<br>
              빠르게 찾아주셨어요. 시간이 촉박한 상황이었는데<br>
              덕분에 빨리 해결했습니다.
            </div>
          </li>
          <li>
            <div class="celebrity-review-category">중견기업 D사</div>
            <div class="celebrity-review-content-title">공연팀이 다양해요</div>
            <div class="celebrity-review-txt">
              행사를 할 때마다 새로운 공연팀을 찾는 것은<br>
              담당자에게는 부담이 되는 일인데요.<br>
              헤이비글에는 <span>다양한 장르의 공연팀들이 많고</span><br>
              <span>견적도 빠르게 알아 볼 수 있어</span>서 자주 이용하고 있습니다.
            </div>
          </li>
        </ul>
      </div>
      <div class="celebrity-inquire" ref="goInquire">
        <div class="celebrity-inquire-title-wrap">
          <h2 class="celebrity-inquire-title">
            <span>연예인, 셀럽</span>
            섭외문의하기
          </h2>
          <p>전문 매니저가 실시간으로 확인하고<span class="inquire_desc_icon">빠르게 답변드릴게요!</span></p>
        </div>
        <div class="celebrity-inquire-form">
          <div>
            <div class="ui-border-line ui-h-0 ui-mb-1"></div>
            <h4>어떤 분을 섭외하고 싶으세요?</h4>
            <div class="ui-border-line"></div>
            <input
              type="text"
              v-model="artistName"
              v-mask="''"
              placeholder="이름 또는 원하는 내용을 알려주세요."
              required
            />
            <div class="ui-border-line" style="height: 10px"></div>
          </div>

          <div>
            <div class="ui-border-line ui-h-0 ui-mb-1"></div>
            <h4>어떤 행사인가요?</h4>
            <div class="ui-border-line"></div>
            <input
              type="text"
              v-model="eventName"
              placeholder="대학교 행사로 20대 대상의 공연입니다 등"
              required
            />
            <div class="ui-border-line" style="height: 10px"></div>
          </div>

          <div>
            <div class="ui-border-line ui-h-0 ui-mb-1"></div>
            <h4>필요한 일정을 알려주세요.</h4>
            <div class="ui-border-line"></div>
            <input
              type="text"
              v-model="runday"
              placeholder="22년 12월 10일 17시~18시까지 등"
              required
            />
            <div class="ui-border-line" style="height: 10px"></div>
          </div>
          <div>
            <div class="ui-border-line ui-h-0 ui-mb-1"></div>
            <h4>행사는 어디에서 진행되나요? (지역+장소)</h4>
            <div class="ui-border-line"></div>
            <input
              type="text"
              v-model="location"
              placeholder="서울 삼성동 코엑스 1층 등"
              required
            />
            <div class="ui-border-line" style="height: 10px"></div>
          </div>
          <div>
            <div class="ui-border-line ui-h-0 ui-mb-1"></div>
            <h4>예산 또는 희망 금액을 알려주세요.</h4>
            <div class="ui-border-line"></div>
            <input
              type="text"
              v-model="inquirePrice"
              placeholder="2,500만원"
              required
            />
            <div class="ui-border-line" style="height: 10px"></div>
          </div>
          <button type="button" :class="validateForm ? 'active' : 'disabled'" @click="save()">섭외 문의하기</button> <!-- Default 비활성화 / 모두 입력시 활성화 "active" 클래스 추가 --> 
        </div>
      </div>
      <!-- Home.vue (home-information) -->
    </div>
  </div>
</template>

<script>
import Modal from '@/components/common/Modal'
import Icon from '@/components/common/Icon'
import BlankSearch from '@/components/blank/BlankList'
import LabelButton from '@/components/common/LabelButton'

export default {
  name: 'Celebrity',
  components: {
    Modal,
    Icon,
    BlankSearch,
    LabelButton
  },
  data() {
    return {
      init: false,
      scroll: {
        lastY: 0,
      },
      runday: '',
      location: '',
      eventName: '',
      artistName: '',
      inquirePrice: '',
      itemType: '',
      genre: '연예인',
      nums: [9300, 4300, 40000],
      duration: 5000,
      interval: 123,
      increments: [],
      currentValues: [],
      isScrollCountEvent: false,
      io: null,
      countInfo: [
        {
          title: '누적 매칭 수',
          icon: 'match',
          number: '9300'
        },
        {
          title: '누적 리뷰 수',
          icon: 'review',
          number: '4300'
        },
        {
          title: '누적 고객 수',
          icon: 'customer',
          number: '40000'
        }
      ],
    }
  },
  computed: {
    pageOptionKeys: {
      get() {
        return [
          'disableResetBackHistory',
          'hiddenSearchConditionController',
          'resetDataOnBack',
        ]
      },
      cache: false,
    },
    pageOptions: {
      get() {
        const pageOptions = {}

        this.pageOptionKeys.forEach(key => {
          switch (key) {
            case 'disableResetBackHistory':
              pageOptions[key] = this.$route?.query?.[key] === 'true' || false
              break

            case 'hiddenSearchConditionController':
              pageOptions[key] = this.$route?.query?.[key] === 'true' || false
              break

            case 'resetDataOnBack':
              pageOptions[key] = this.$route?.query?.[key] === 'true' || false
              break

            default:
              pageOptions[key] = this.$route?.query?.[key] || null
              break
          }
        })

        return pageOptions
      },
      cache: false,
    },
    query: {
      get() {
        const nextQueryString = {}

        for (const key in this.$route.query) {
          const val = this.$route.query[key]

          if (this.pageOptionKeys.indexOf(key) === -1) {
            nextQueryString[key] = val
          }
        }

        return nextQueryString
      },
      cache: false,
    },
    validateForm: {
      get() {
        let valid = true;
        if(!this.runday || !this.eventName || !this.location || !this.artistName || !this.inquirePrice) {
          valid = false;
        }
        return valid;
      },
    }
  },
  beforeMount() {
    this.setTitle()
  },
  beforeRouteEnter(to, from, next) {
    next(Vue => {
      Vue.setTitle()
      if (Vue.init) {
        Vue.$refs.container.scrollTop = Vue.scroll.lastY
      }
    })
  },
  mounted() {
    this.increments = this.nums.map(n => Math.ceil(n / (this.duration / this.interval)));
    this.currentValues = this.nums.map(() => 0);

    const io = new IntersectionObserver((entries, observer) => {
          entries.forEach((box) => {
              if (box.isIntersecting && box.intersectionRatio >= 0.5) {
                  this.updateCounters();
                  observer.disconnect();
              }
          });
      }, {threshold: 1});

      // ref를 이용하여 컴포넌트의 엘리먼트에 접근
      io.observe(this.$refs.intersectionObserverTarget);
  },
  methods: {
    setTitle() {
      let title = ''
      let query = this.$route.query
      if (query.genre) title = query.genre
      if (query.contents) title = query.contents
      this.$store.commit('setGnbTitle', title)
    },
    scrollToElement() {
      const targetElement = this.$refs.goInquire;
      
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    },
    save() {
      const req = {
        method: 'post',
        url: `/client/cusOrder/celebrity`,
        data: {
          genre: "연예인",
          location: this.location,
          runday: this.runday,
          artistName: this.artistName,
          eventName: this.eventName,
          inquirePrice: this.inquirePrice,
          itemType: ""
        }
      }

      return this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data);
          const resultData = this.$lib.resultCheck(data.resultData);

          if (resultData.success) {
            this.$toast("문의가 접수되었습니다.").push("/home");
          } else {
            this.$alert(resultData);
          }

        })
        .catch(log.error)
    },
    updateCounters() {
        this.currentValues = this.currentValues.map((value, index) => {
            value += this.increments[index];
            return Math.min(value, this.nums[index]);
        });

        if (!this.currentValues.every((value, index) => value === this.nums[index])) {
            requestAnimationFrame(() => this.updateCounters());
        }
    },
  },
}
</script>

<style lang="scss" scoped>
#page > .container {
  display: flex;
  flex-direction: column;
  padding: 0;

  .list-option {
    overflow: hidden;

    > div {
      float: left;
      width: 50%;
      text-align: left;
      color: #919191;
      color: #000;
      font-size: 1.8rem;
      line-height: 5.5rem;
      padding: 0 3rem;

      &:first-child {
        border-right: 1px solid #ececec;
      }

      .icon {
        float: right;
      }
    }
  }

  .scroll-container {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 2rem;
    // padding-right: 3rem;
    // padding-left: 3rem;
  }

  .celebrity-banner {
    position: relative;
    width: 100%;
    height: 54rem;
    &-bg {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8rem 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .celebrity-banner-txt {
        margin-bottom: 0;
        font-size: 4rem;
        font-weight: 700;
        line-height: 5.4rem;
        color: #fff;
        text-align: center;
      }
      .celebrity-banner-btns {
        display: flex;
        flex-direction: column;
        gap: 3.4rem 0;
        button {
          padding: 2rem 4rem;
          border-radius: 0.8rem;
          font-size: 2.2rem;
          font-weight: 700;
          line-height: 3.4rem;
          color: #000;
          &.btn-celebrity-inquiry {
            background-color: #f6f6f6;
          }
          &.btn-celebrity-chat {
            background-color: #fff735;
            span {
              position: relative;
              padding-left: 4rem;
              color: #000;
              &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 3rem;
                height: 3rem;
                background: url(../../../public//img/artist/chat.png) no-repeat center / 100% 100%;
              }
            }
          }
        }
      }
    }
  }

  .celebrity-marquee {
    margin-top: 2rem;
    padding: 3rem 0;
    &-inner {
      position: relative;
      width: 100%;
      height: 4.8rem;
      & + .celebrity-marquee-inner {
        margin-top: 4rem;
      }
      ul {
        display: flex;
        gap: 0 2rem;
        width: 100%;
        height: 100%;
        animation: celebrity-marquee 30s linear infinite;
        &.marquee-copy {
          position: absolute;
          top: 0;
          left: 0;
          // transform: translate(358rem);
          transform: translate(178rem);
          animation: celebrity-marquee-copy 30s linear infinite;
        }
        li {
          flex-shrink: 0;
          width: 16rem;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      &.reverse ul {
        animation-direction: reverse;
      }
    }
  }
  
  @keyframes celebrity-marquee {
    0% {
        transform: translateX(0);
    }

    100% {
        // transform: translateX(-3580px);
        transform: translateX(-178rem);
    }
  }

  @keyframes celebrity-marquee-copy {
    0% {
        // transform: translateX(3580px);
        transform: translateX(178rem);
    }

    100% {
        transform: translateX(0);
    } 
  }

  .celebrity-count-content {
    height: 21.8rem;
    margin-top: 6rem;
    background: url('../../../public/img/artist/bg_celebrity.png') no-repeat center / 100% 100%;
    .celebrity-count-title {
      margin-bottom: 0;
      font-size: 2.8rem;
      font-weight: 700;
      line-height: 3.4rem;
      text-align: center;
      span {
        position: relative;
        display: inline-block;
        &::before {
          content: '';
          position: absolute;
          top: 1.7rem;
          left: 0;
          width: 100%;
          height: 1.8rem;
          background-color: #ab54bb;
          opacity: 0.2;
        }
      }
    } 
    ul {
      display: flex;
      justify-content: center;
      gap: 0 4rem;
      padding-top: 6.05rem;
      li {
        .celebrity-count-subtitle {
          position: relative;
          margin-bottom: 1.6rem;
          font-size: 1.6rem;
          font-weight: 700;
          line-height: 2.4rem;
          text-align: center;
        }
        span {
          position: relative;
          font-size: 3.6rem;
          font-weight: 900;
          line-height: 5.04rem;
          color: #ab54bb;
        } 
      } 
    }
  }

  .celebrity-info {
    margin-top: 6rem;
    &-inner {
      padding: 6rem 0;
      background-color: #f7f8fe;
    }
    &-title {
      margin-bottom: 0;
      font-size: 2.8rem;
      font-weight: 700;
      line-height: 3.92rem;
      text-align: center;
      span {
        font-weight: 700;
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2rem 0;
      margin-top: 2.8rem;
      padding: 0 2.8rem;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem 0;
        padding: 2rem;
        border-radius: 1.2rem;
        background-color: #fff;
        box-shadow: 0 0.4rem 1rem 0 #0000000A;
        .celebrity-info-txt {
            &-title {
              margin-bottom: 0.4rem;
              font-size: 2rem;
              font-weight: 700;
              line-height: 2.8rem;
              color: #ab54bb;
            } 
            span {
              font-size: 1.6rem;
              line-height: 2.2rem;  
            }
        }
      }
    }
    &-img {
      width: 10rem;
      height: 10rem;
      &.time {
        background: url('../../../public/img/artist/icon_info_time.png') no-repeat center / 100% 100%;
      }
      &.expert {
        background: url('../../../public/img/artist/icon_info_expert.png') no-repeat center / 100% 100%;
      }
      &.sequre {
        background: url('../../../public/img/artist/icon_info_sequre.png') no-repeat center / 100% 100%;
      }
      &.noshow {
        background: url('../../../public/img/artist/icon_info_noshow.png') no-repeat center / 100% 100%;
      }
    }
  }
  .celebrity-review {
    padding: 6rem 0;
    background-color: #f5f5f5;
    // background-color: #e6efff;
    &-title {
      margin-bottom: 0;
      font-size: 2.8rem;
      font-weight: 700;
      line-height: 3.4rem;
      text-align: center;
    }
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      margin-top: 2.8rem;
      padding: 0 2.8rem;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem 0;
        width: 100%;
        padding: 4rem 2.8rem;
        border-radius: 1.6rem;
        box-shadow: 0 0.4rem 1rem 0 #0000000A;
        background-color: #fff;
        text-align: center;
        .celebrity-review-category {
          font-size: 1.8rem;
          font-weight: 700;
          line-height: 2.5rem;
          color: #ab54bb;
        }
        .celebrity-review-content-title {
          font-size: 2.4rem;
          font-weight: 700;
          line-height: 3.4rem;
        }
        .celebrity-review-txt {
          font-size: 1.6rem;
          line-height: 2.2rem;
          span {
            position: relative;
            font-weight: 700;
            &::before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 0.8rem;
              background-color: #ab54bb;
              opacity: 0.2;
            }
          }
        }
      }
    }
  }

  .celebrity-inquire {
    padding: 6rem 0;
    &-title-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 4.4rem;
      .celebrity-inquire-title {
        margin-bottom: 2rem;
        font-size: 2.8rem;
        font-weight: 700;
        line-height: 3.9rem;
        text-align: center;
        > span {
          position: relative;
          &::before {
            content: '';
            position: absolute;
            top: 1.7rem;
            left: 0;
            width: 100%;
            height: 1.6rem;
            background-color: #ab54bb;
            opacity: 0.2;
          }
        }
      }
      p {
        font-size: 2rem;
        line-height: 2.8rem;
        text-align: center;
        color: #666;
        .inquire_desc_icon {
          position: relative;
          display: block;
          width: fit-content;
          margin: 0 auto;
          padding-right: 2.3rem;
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            right: -0.3rem;
            width: 2rem;
            height: 2.8rem;
            background: url('../../../public/img/artist/icon_inquire.png') no-repeat center / cover;
            transform: translateY(-50%);
          }
        }
      }
    }
    &-form {
      padding: 0 3rem;
      h4 {
        margin: 0 !important;
        font-weight: 500 !important;
        font-size: 2.4rem !important;
        line-height: 6.6rem !important;
      }

      input,
      textarea {
        padding: 0;
        width: 100%;
        border: 0;
        font-size: 2rem;
        line-height: 6rem;
      }

      textarea {
        line-height: 3rem;
        min-height: 14rem;
        padding: 3rem 0;
      }

      p {
        color: #979797;
        font-size: 2rem;
      }

      input:invalid ~ button,
      textarea:invalid ~button {
        background: #000;
      }

      button {
        position: relative;
        display: block;
        width: 100%;
        margin-top: 2.6rem;
        border-radius: 6rem;
        background: #f5f5f5;
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 8rem;
        color: #999;
        &.active {
          background: linear-gradient(97.67deg, #D391FF 7.45%, #A722FF 53.52%, #6BCAFF 101.56%);
          color: #fff;
        }
      }
    }
  }

  .home-information {
    // margin: 0 -2.8rem;
    margin: 0;
    padding: 5.2rem 2.8rem;
    background-color: #f5f5f5;
    .info-list {
      width: 100%;
      max-width: 76.8rem;
      height: 29.5rem;
      .info-item {
        width: inherit;
        height: inherit;
        position: relative;
        background-color: #7ad8e4;
        .icon {
          position: absolute;
          left: 3rem;
          bottom: 0;
        }
        .title {
          position: absolute;
          top: 7.2rem;
          left: 26rem;
          font-size: 3rem;
          color: #fff;
        }
        .body {
          position: absolute;
          top: 13rem;
          left: 26rem;
          font-size: 1.6rem;
          white-space: pre-line;
          color: #fff;
        }
      }
    }
    .info-company {
      color: #999;
      .ui-label-button {
        .ui-label-button-text {
          line-height: normal !important;
        }
      }
      .toggle-area {
        padding: 2.4rem 0;
        color: inherit;
        font-size: 1.8rem;
        line-height: 2.6rem;
        font-weight: 500;
        a {
          color: inherit;
        }
      }
    }

    .call-center {
      font-size: 1.8rem;
      line-height: 2.6rem;
      letter-spacing: -0.09px;
      font-weight: 500;
      .call-center-num {
        margin-bottom: 0.4rem;
        color: #ab54bb;
        font-size: 2.8rem;
        font-weight: 600;
        line-height: 3.6rem;
        letter-spacing: -0.14px;
      }
    }
    .sns-list {
      display: flex;
      margin-top: 1.6rem;
      li {
        a {
          display: block;
          width: 3.3rem;
          img {
            display: block;
            width: 100%;
          }
        }
        & ~ li {
          margin-left: 1.8rem;
        }
      }
    }
  }
}
</style>
